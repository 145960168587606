import axios from "axios";
import config from "../config.json";
//import config from "./config.json";

const url = config.REACT_APP_URL;
// const url = "https://rubywines.in";

//for cust_det records
const custmasterCreate = async (param) => {
  return await axios.post(`${url}/trfs/cust_det_trf`, { ...param });
};

//for cust_recs rec records
const cust_recsCreate = async (param) => {
  return await axios.post(`${url}/trfs/cust_rec_trf`, { ...param });
};

//for cust_deporecs rec records
const cust_tranCreate = async (param) => {
  return await axios.post(`${url}/trfs/cust_tran_trf`, { ...param });
};

export { custmasterCreate, cust_recsCreate, cust_tranCreate };
