import React from "react";
import { NavLink, Outlet } from "react-router-dom";

function Transfers() {
  return (
    <>
      <div>
        <nav>
          <NavLink to={"cmaster"}>C-Master</NavLink>
          <NavLink to={"cinvestment"}>C-Investment</NavLink>
          <NavLink to={"cdeposits"}>C-Deposits</NavLink>
        </nav>
        <Outlet />
      </div>
    </>
  );
}

export default Transfers;
