// components/CSVSelector.tsx
import React from "react";
import Papa from "papaparse";

const CSVSelector = ({ onChange }) => {
  const handleFileChange = async (e) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0];

        Papa.parse(file, {
          worker: true, // use a web worker so that the page doesn't hang up
          header: true,
          skipEmptyLines: true,
          complete({ data }) {
            onChange(data);
          },
        });
        // 6. call the onChange event
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <input
      style={{ width: 100 + "%" }}
      type="file"
      accept=".csv"
      onChange={handleFileChange}
    />
  );
};

export default CSVSelector;
