//use NavLink to get the active class i.e active class would
//be applied to NavLink Item
import React from "react";
import { NavLink } from "react-router-dom";
// import { Link } from "react-router-dom";

//this active can be done through css like this was in "index.css"
// nav a.active {
//   font-weight: bold;
//   font-size: 23px;
// }

function Navbar() {
  return (
    <nav className="main-nav">
      <NavLink className="nav" to="/">
        Home
      </NavLink>
      <NavLink className="nav" to="/transfers">
        Transfers
      </NavLink>
    </nav>
  );
}

export default Navbar;
