import { useState } from "react";
import { cust_tranCreate } from "../api/fetchApi";
import CSVSelector from "./CSVSelector";

function Cdepositstrf() {
  const [data, setData] = useState([]);
  const [statusbar, setStatusbar] = useState("");
  let addctr = 0;
  let updatectr = 0;
  let errctr = 0;
  let [addcnt, setAddcnt] = useState(0);
  let [updatecnt, setUpdatecnt] = useState(0);
  let [errorcnt, setErrorcnt] = useState(0);

  const lowerize = (obj) =>
    Object.keys(obj).reduce((acc, k) => {
      acc[k.toLowerCase()] = obj[k];
      return acc;
    }, {});

  const addCtr = (typ) => {
    (typ = "U") && updatectr++;
    (typ = "A") && addctr++;
    (typ = "E") && errctr++;
  };

  const uploadRecords = () => {
    updatectr = 0;
    addctr = 0;
    errctr = 0;
    setAddcnt(addctr);
    setUpdatecnt(updatectr);
    setErrorcnt(errctr);

    setStatusbar("Processing Records....");
    console.log("dataaza", data);

    const insert_rec = async () => {
      for (let index = 0; index < data.length; index++) {
        setStatusbar("Processing Records...." + index + " of " + data.length);
        const element = lowerize(data[index]);
        console.log("element", element);

        await cust_tranCreate(element)
          .then((response) => {
            setStatusbar("Records Insertion Done");

            if (response.status === 200) {
              addCtr("U");
              setUpdatecnt(updatectr);
            }
            if (response.status === 201) {
              addCtr("A");
              setAddcnt(addctr);
            }
            console.log(response);
          })
          .catch((error) => {
            console.log("Error: Insertion");
            addCtr("E");
            setErrorcnt(errctr);
            console.log(error);
          });
      }
      setStatusbar("Insertion Completed");
    };
    insert_rec();
  };

  return (
    <div className="App">
      <h4>Status: {statusbar}</h4>
      <h4>
        Added: {addcnt} &nbsp;&nbsp; &nbsp; Update: {updatecnt} &nbsp;&nbsp;
        &nbsp;Error:{errorcnt}
      </h4>
      <CSVSelector onChange={(_data) => setData(_data)} />
      <button
        className="btn btn-primary"
        onClick={() => {
          if (window.confirm("!!!Transfer The Deposit Details File ?")) {
            uploadRecords();
          }
        }}
      >
        Upload Data
      </button>
      <br />
      {data.length > 0 && (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              {Object.keys(data[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <br />
      <br />
    </div>
  );
}

export default Cdepositstrf;
