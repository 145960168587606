import "./App.css";
//import { Route, Routes, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Cmastertrf from "./Components/Cmastertrf";
import Cinvestmenttrf from "./Components/Cinvestmenttrf";
// import Cdepositstrf from "./Components/Cdepositstrf";
import Navbar from "./Components/Navbar";
import Transfers from "./Components/Transfers";
import Home from "./Components/Home";
import Cdepositstrf from "./Components/Cdepositstrf";

function App() {
  //const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* this is index route which will be called automatically with Parent product route */}
        {/* this should be written immediatly after parent route */}
        <Route index element={<Home />} />
        <Route path="/transfers" element={<Transfers />}>
          <Route path="cmaster" element={<Cmastertrf />} />
          <Route path="cinvestment" element={<Cinvestmenttrf />} />
          <Route path="cdeposits" element={<Cdepositstrf />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
